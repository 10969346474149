import React from "react";
import { Pane, Autocomplete, TextInput } from "evergreen-ui";
import { useHistory } from "react-router-dom";
import { Divaluate } from "./Design";

const Navbar = ({ allTickers }) => {
  const history = useHistory();

  return (
    <Pane
      padding={16}
      marginTop={-8}
      marginLeft={-8}
      marginRight={-8}
      backgroundColor="#6000d3"
      alignItems="center"
      display="flex"
      justifyContent="space-between"
    >
      <Pane display="flex" width="1000%" className="brandText">
        <Divaluate size={900} />
      </Pane>
      <Autocomplete
        title="Ticker"
        onChange={(changedItem) => {
          history.push(`/ticker/${changedItem}`);
          history.go(0);
        }}
        items={allTickers}
      >
        {(props) => {
          const { getInputProps, getRef, inputValue, openMenu } = props;
          return (
            <TextInput
              placeholder="Search For Dividend Stock Ticker.."
              value={inputValue}
              ref={getRef}
              {...getInputProps({
                onFocus: () => {
                  openMenu();
                },
              })}
            />
          );
        }}
      </Autocomplete>
    </Pane>
  );
};

export default Navbar;
