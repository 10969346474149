import { Badge, Heading, Pane, Text } from "evergreen-ui";
import React from "react";
import { numberWithCommas } from "../../utils";
import { NEUTRAL_BADGE_COLOR } from "../../utils";

// Key Statistics Page
const OtherInfo = ({ data }) => {
  const TRAILING_PE = data.trailingPE;
  const CURRENCY = data.currency;
  const FIFTY_TWO_WEEK_LOW = data.fiftyTwoWeekLow;
  const FIFTY_TWO_WEEK_HIGH = data.fiftyTwoWeekHigh;
  const EPS_FORWARD = data.epsForward;
  const PRICE_TO_BOOK = data.priceToBook;
  const AVERAGE_DAILY_VOLUME_3_MONTH = data.averageDailyVolume3Month;
  const AVERAGE_DAILY_VOLUME_10_DAY = data.averageDailyVolume10Day;
  const REGULAR_MARKET_VOLUME = data.regularMarketVolume;
  const EXCHANGE = data.exchange;
  const REGULAR_MARKET_DAY_LOW = data.regularMarketDayLow;
  const REGULAR_MARKET_DAY_HIGH = data.regularMarketDayHigh;
  const REGULAR_MARKET_PREVIOUS_CLOSE = data.regularMarketPreviousClose;
  const REGULAR_MARKET_OPEN = data.regularMarketOpen;
  const SHARES_OUTSTANDING = data.sharesOutstanding;
  const TRAILING_ANNUAL_DIVIDEND_RATE = data.trailingAnnualDividendRate;
  const TRAILING_ANNUAL_DIVIDEND_YIELD = data.trailingAnnualDividendYield;
  //   const EXCHANGE_DATA_DELAYED_BY = data.exchangeDataDelayedBy;

  const MONTHS = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec",
  };

  const DAYS = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };

  function getDate(utcSeconds) {
    if (utcSeconds !== "N/A") {
      let d = new Date(0); // The 0 there is the key, which sets the date to the epoch
      d.setUTCSeconds(utcSeconds);

      return (
        DAYS[d.getDay()] +
        ", " +
        MONTHS[d.getUTCMonth()] +
        " " +
        d.getDate() +
        ", " +
        d.getFullYear()
      );
    }
    return "N/A";
  }
  const DIVIDEND_DATE = getDate(data.dividendDate);

  //   const PRICE = data.price;
  //   const DIVIDEND_DATE = data.dividendDate;
  //   const TRAILING_ANNUAL_DIVIDEND_RATE = data.trailingAnnualDividendRate;
  //   const TRAILING_ANNUAL_DIVIDEND_YIELD = data.trailingAnnualDividendYield;
  //   const LONG_NAME = data.longName;
  //   const BOOK_VALUE = data.bookValue;

  function outputValidation(value, symbolBefore = "", symbolAfter = "") {
    if (value !== "N/A") {
      value = symbolBefore + value + symbolAfter;
    }
    return value;
  }

  function exchangeConverter(exchange) {
    if (exchange === "NMS") {
      return "NASDAQ";
    } else if (exchange === "NYQ") {
      return "NYSE";
    } else if (exchange === "TOR") {
      return "TSX";
    } else {
      return exchange;
    }
  }

  function fixIfNumber(value, decimalPlaces) {
    if (value !== "N/A" && typeof value === "number") {
      return value.toFixed(decimalPlaces);
    }
    return value;
  }

  function addCommasIfNumber(value) {
    if (value !== "N/A" && typeof value === "number") {
      return numberWithCommas(value);
    }
    return value;
  }

  return (
    <Pane>
      <Heading marginBottom={4} size={600}>
        Key Statistics
      </Heading>
      <Pane
        display="flex"
        flexDirection="row"
        border="default"
        background="rgba(96,0,211,0.05)"
        padding={8}
        marginBottom={8}
      >
        <Pane width={"50%"} borderRight="muted" marginRight={8}>
          <Heading size={400} marginBottom={8}>
            Stock Price History
          </Heading>
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
            paddingBottom={8}
          >
            <Text>Previous Close</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(REGULAR_MARKET_PREVIOUS_CLOSE)}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
            paddingBottom={8}
          >
            <Text>Open</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(REGULAR_MARKET_OPEN)}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
            paddingBottom={8}
          >
            <Text>Day's Range</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {REGULAR_MARKET_DAY_LOW === "N/A" ||
              REGULAR_MARKET_DAY_HIGH === "N/A"
                ? "N/A"
                : `${fixIfNumber(REGULAR_MARKET_DAY_LOW, 2)} - ${fixIfNumber(
                    REGULAR_MARKET_DAY_HIGH,
                    2
                  )}`}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
            paddingBottom={8}
          >
            <Text>52 Week Range</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {FIFTY_TWO_WEEK_LOW === "N/A" || FIFTY_TWO_WEEK_HIGH === "N/A"
                ? "N/A"
                : `${fixIfNumber(FIFTY_TWO_WEEK_LOW, 2)} - ${fixIfNumber(
                    FIFTY_TWO_WEEK_HIGH,
                    2
                  )}`}
            </Badge>
          </Pane>

          <Heading size={400} marginBottom={8}>
            Share Statistics
          </Heading>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
            paddingBottom={8}
          >
            <Text>Volume</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(addCommasIfNumber(REGULAR_MARKET_VOLUME))}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
            paddingBottom={8}
          >
            <Text>Average Volume (10 Days)</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(addCommasIfNumber(AVERAGE_DAILY_VOLUME_10_DAY))}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
            paddingBottom={8}
          >
            <Text>Average Volume (3 Months)</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(
                addCommasIfNumber(AVERAGE_DAILY_VOLUME_3_MONTH)
              )}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
          >
            <Text>Shares Outstanding</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(addCommasIfNumber(SHARES_OUTSTANDING))}
            </Badge>
          </Pane>
        </Pane>

        <Pane width={"49%"}>
          <Heading size={400} marginBottom={8}>
            Dividends
          </Heading>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={8}
          >
            <Text>Trailing Annual Dividend Rate</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(
                addCommasIfNumber(fixIfNumber(TRAILING_ANNUAL_DIVIDEND_RATE, 2))
              )}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={8}
          >
            <Text>Trailing Annual Dividend Yield</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(
                addCommasIfNumber(
                  fixIfNumber(
                    TRAILING_ANNUAL_DIVIDEND_YIELD !== "N/A"
                      ? TRAILING_ANNUAL_DIVIDEND_YIELD * 100
                      : "N/A",
                    2
                  )
                ),
                "",
                "%"
              )}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={8}
          >
            <Text>Dividend Date</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(DIVIDEND_DATE)}
            </Badge>
          </Pane>

          <Heading size={400} marginBottom={8}>
            Valuation Measures
          </Heading>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={8}
          >
            <Text>Forward EPS</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(fixIfNumber(EPS_FORWARD, 2))}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={8}
          >
            <Text>Trailing P/E</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(fixIfNumber(TRAILING_PE, 2))}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={8}
          >
            <Text>Price to Book</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(fixIfNumber(PRICE_TO_BOOK, 2))}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingBottom={8}
          >
            <Text>Currency</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {outputValidation(CURRENCY)}
            </Badge>
          </Pane>

          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text>Exchange</Text>
            <Badge color={NEUTRAL_BADGE_COLOR}>
              {exchangeConverter(outputValidation(EXCHANGE))}
            </Badge>
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default OtherInfo;
