import { Badge, Heading, Pane, Text } from "evergreen-ui";
import React from "react";
import { numberWithCommas } from "../../utils";

const FitOfModel = ({ data }) => {
  const SD = data.standardDeviation;
  const SDColors = {};
  const OtherIndicatorColors = {};

  const PAYOUT_RATIO = data.payoutRatio;

  SDColors["AT_abs"] = "purple";

  if (SD.percentage < 0.075) {
    SDColors["AT_rel"] = "green";
  } else if (SD.percentage < 0.125) {
    SDColors["AT_rel"] = "yellow";
  } else {
    SDColors["AT_rel"] = "red";
  }

  if (SD.absoluteLast5Years < SD.absolute * 0.75) {
    SDColors["L5Y_abs"] = "green";
  } else if (SD.absoluteLast5Years < SD.absolute * 1.25) {
    SDColors["L5Y_abs"] = "yellow";
  } else {
    SDColors["L5Y_abs"] = "red";
  }

  if (SD.percentageLast5Years < 0.025) {
    SDColors["L5Y_rel"] = "green";
  } else if (SD.percentageLast5Years < 0.075) {
    SDColors["L5Y_rel"] = "yellow";
  } else {
    SDColors["L5Y_rel"] = "red";
  }

  if (0 < PAYOUT_RATIO && PAYOUT_RATIO < 0.75) {
    OtherIndicatorColors["PayoutRatio"] = "green";
  } else if (0 < PAYOUT_RATIO && PAYOUT_RATIO < 1.25) {
    OtherIndicatorColors["PayoutRatio"] = "yellow";
  } else {
    OtherIndicatorColors["PayoutRatio"] = "red";
  }

  if (data.marketCap > 10000000000) {
    OtherIndicatorColors["MarketCap"] = "green";
  } else if (data.marketCap > 100000000) {
    OtherIndicatorColors["MarketCap"] = "yellow";
  } else {
    OtherIndicatorColors["MarketCap"] = "red";
  }

  function outputValidation(value, symbolBefore = "", symbolAfter = "") {
    if (value !== "N/A") {
      value = symbolBefore + value + symbolAfter;
    }
    return value;
  }

  function fixIfNumber(value, decimalPlaces) {
    if (value !== "N/A" && typeof value === "number") {
      return value.toFixed(decimalPlaces);
    }
    return value;
  }

  function addCommasIfNumber(value) {
    if (value !== "N/A" && typeof value === "number") {
      return numberWithCommas(value);
    }
    return value;
  }

  return (
    <Pane>
      <Heading marginBottom={4} size={600}>
        Fit of Model
      </Heading>
      <Pane
        display="flex"
        flexDirection="column"
        border="default"
        background="rgba(96,0,211,0.05)"
        padding={8}
        marginBottom={8}
      >
        <Heading size={400} marginBottom={8}>
          Standard Deviation - All Time
        </Heading>
        <Pane display="flex" flex={1} marginBottom={8}>
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
            flex={1}
          >
            <Text>Absolute</Text>
            <Badge color={SDColors.AT_abs}>
              {outputValidation(fixIfNumber(SD.absolute, 4))}
            </Badge>
          </Pane>
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingLeft={8}
            flex={1}
          >
            <Text>Relative</Text>
            <Badge color={SDColors.AT_rel}>
              {outputValidation(fixIfNumber(SD.percentage * 100, 2), "", "%")}
            </Badge>
          </Pane>
        </Pane>

        <Heading size={400} marginBottom={8}>
          Standard Deviation - Last 5 Years
        </Heading>
        <Pane display="flex" flex={1} marginBottom={8}>
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingRight={8}
            flex={1}
          >
            <Text>Absolute</Text>
            <Badge color={SDColors.L5Y_abs}>
              {outputValidation(fixIfNumber(SD.absoluteLast5Years, 4))}
            </Badge>
          </Pane>
          <Pane
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            paddingLeft={8}
            flex={1}
          >
            <Text>Relative</Text>
            <Badge color={SDColors.L5Y_rel}>
              {outputValidation(
                fixIfNumber(SD.percentageLast5Years * 100, 2),
                "",
                "%"
              )}
            </Badge>
          </Pane>
        </Pane>
        <Pane borderBottom="muted" marginBottom={8}></Pane>

        <Heading size={400} marginBottom={8}>
          Other Indicators
        </Heading>
        <Pane
          display="flex"
          flex={1}
          alignItems="center"
          marginBottom={8}
          justifyContent="space-between"
        >
          <Text>Payout Ratio</Text>
          <Badge color={OtherIndicatorColors.PayoutRatio}>
            {outputValidation(
              fixIfNumber(
                PAYOUT_RATIO !== "N/A" ? PAYOUT_RATIO * 100 : PAYOUT_RATIO,
                2
              ),
              "",
              "%"
            )}
          </Badge>
        </Pane>
        <Pane
          display="flex"
          flex={1}
          alignItems="center"
          paddingBottom={3.6}
          justifyContent="space-between"
        >
          <Text>Market Cap</Text>
          <Badge color={OtherIndicatorColors.MarketCap}>
            {outputValidation(addCommasIfNumber(data.marketCap), "")}
          </Badge>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default FitOfModel;
