import { Heading } from "evergreen-ui";
import React from "react";
import { COLORS } from "../utils";

export const Divaluate = ({ size = 900 }) => {
  return (
    <>
      <Heading
        size={size}
        fontWeight={parseInt((size / 3) * 2)}
        color={COLORS.SECONDARY}
      >
        Div
      </Heading>
      <Heading size={size} fontWeight={parseInt(size / 4.5)} color="white">
        aluate
      </Heading>
    </>
  );
};

export const DivaluateDiv = ({ size = 900, color = COLORS.SECONDARY }) => {
  return (
    <>
      <Heading size={size} fontWeight={parseInt((size / 3) * 2)} color={color}>
        Div
      </Heading>
    </>
  );
};
