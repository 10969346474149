import { Heading, Pane, Badge, Text } from "evergreen-ui";
import { COLORS } from "../../utils";
import { DivaluateDiv } from "../Design";

export const DivScore = ({ data }) => {
  const DIV_SCORE = data.divaluateScore.overallScore;
  const FIT_OF_MODEL = data.divaluateScore.fitOfModel;
  const RETURN_SCORE = data.divaluateScore.potentialReturn;
  const textColor = {};
  if (!isNaN(DIV_SCORE)) {
    if (DIV_SCORE >= 50) {
      textColor["DIV_SCORE"] = "green";
    } else if (DIV_SCORE >= 25) {
      textColor["DIV_SCORE"] = COLORS.PRIMARY;
    } else {
      textColor["DIV_SCORE"] = "red";
    }
  }
  if (!isNaN(FIT_OF_MODEL)) {
    if (FIT_OF_MODEL >= 65) {
      textColor["FIT_OF_MODEL"] = "green";
    } else if (FIT_OF_MODEL >= 35) {
      textColor["FIT_OF_MODEL"] = "yellow";
    } else {
      textColor["FIT_OF_MODEL"] = "red";
    }
  }
  if (!isNaN(RETURN_SCORE)) {
    if (RETURN_SCORE >= 80) {
      textColor["RETURN_SCORE"] = "green";
    } else if (RETURN_SCORE >= 60) {
      textColor["RETURN_SCORE"] = "yellow";
    } else {
      textColor["RETURN_SCORE"] = "red";
    }
  }

  return (
    <Pane>
      <Pane
        display="flex"
        marginBottom={4}
        justifyContent="flex-end"
        alignItems="center"
      >
        <DivaluateDiv color={COLORS.PRIMARY} size={800} />
        <Heading size={800} marginRight={8} fontWeight={300}>
          score:
        </Heading>
        <Heading size={800} color={textColor.DIV_SCORE}>
          {data.divaluateScore.overallScore}
        </Heading>
      </Pane>
      <Pane>
        <Pane display="flex" alignItems="center" justifyContent="flex-end">
          <Text marginRight={8}>Fit of Model Score:</Text>
          <Badge color={textColor.FIT_OF_MODEL}>{FIT_OF_MODEL}</Badge>
        </Pane>

        <Pane display="flex" alignItems="center" justifyContent="flex-end">
          <Text marginRight={8}>Potential Return Score:</Text>
          <Badge color={textColor.RETURN_SCORE}>
            {data.divaluateScore.potentialReturn}
          </Badge>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default DivScore;
