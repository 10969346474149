import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Ticker from "./components/Ticker";
import { BACKEND_URL } from "./config/config";

function App() {
  const [allTickers, setAllTickers] = useState([]);
  useEffect(() => {
    const url = new URL(`${BACKEND_URL}allTickers`);

    fetch(url)
      .then((res) => res.json())
      .then((data) =>
        setAllTickers(
          [...data.TSX, ...data.NYSE, ...data.NASDAQ].sort((a, b) =>
            a.localeCompare(b)
          )
        )
      );
  }, []);

  return (
    <Router>
      <Navbar allTickers={allTickers} />
      <Switch>
        <Route
          exact
          path="/ticker/:tickerSymbol"
          render={(props) => <Ticker {...props} />}
        />
        <Route path="/" render={() => <Redirect to="/ticker/TD.TO" />} />
      </Switch>
    </Router>
  );
}

export default App;
