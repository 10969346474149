import { Heading, Pane } from "evergreen-ui";
import React from "react";

export const StockPrice = ({ data, ticker }) => {
  const STOCK_NAME = (
    data.longName ? data.longName : data.shortName
  ).toUpperCase();
  const CHANGE_FROM_PREV_CLOSE = (
    data.price - data.regularMarketPreviousClose
  ).toFixed(2);
  const CHANGE_FROM_PREV_CLOSE_PERCENT = (
    ((data.price - data.regularMarketPreviousClose) /
      data.regularMarketPreviousClose) *
    100
  ).toFixed(2);
  const CHANGE_FROM_PREV_CLOSE_SIGN = CHANGE_FROM_PREV_CLOSE >= 0 ? "+" : "";
  const CHANGE_FROM_PREV_CLOSE_COLOR =
    CHANGE_FROM_PREV_CLOSE >= 0 ? "green" : "red";

  return (
    <Pane>
      <Heading marginBottom={8} size={600}>
        {STOCK_NAME} ({ticker})
      </Heading>
      <Pane display="flex" marginBottom={16} alignItems="flex-end">
        <Heading marginRight={8} size={900}>
          ${parseFloat(data.price).toFixed(2)}
        </Heading>
        <Heading size={600} color={CHANGE_FROM_PREV_CLOSE_COLOR}>
          {CHANGE_FROM_PREV_CLOSE_SIGN}
          {CHANGE_FROM_PREV_CLOSE} ({CHANGE_FROM_PREV_CLOSE_SIGN}
          {CHANGE_FROM_PREV_CLOSE_PERCENT}%)
        </Heading>
      </Pane>
    </Pane>
  );
};

export default StockPrice;
