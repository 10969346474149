import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

const Chart = ({ data }) => {
  const [historicalDividends, setHistoricalDividends] = useState([]);
  const [predictedDividends, setPredictedDividends] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    const historicalDividends = [];
    const predictedDividends = [];
    const labels = [];

    for (const [key, value] of Object.entries(data.projectedDividendsLoF)) {
      labels.push(key);
      predictedDividends.push(value);
    }

    for (const label of labels) {
      const value = data.dividendHistory[label] || 0;
      historicalDividends.push(value);
    }

    historicalDividends[10] =
      data.projectedCurrYearDividend.projectedAmount || 0;

    setHistoricalDividends(historicalDividends);
    setPredictedDividends(predictedDividends);
    setLabels(labels);
  }, [data]);

  const state = {
    series: [
      {
        name: "Historical Dividend",
        type: "column",
        data: historicalDividends,
      },
      {
        name: "Predicted Dividend",
        type: "line",
        data: predictedDividends,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        stacked: false,
      },
      stroke: {
        width: [0, 3],
        curve: "straight",
      },
      plotOptions: {
        bar: {
          columnWidth: "75%",
        },
      },
      colors: ["#6000d3", "#9df107"],
      fill: {
        opacity: [0.85, 1],
      },
      labels: labels,
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        title: {
          text: "Yearly Dividend",
        },
        min: 0,
      },
      tooltip: {
        shared: true,
        intersect: false,
        y: {
          formatter: function (y) {
            return "$" + y;
          },
        },
      },
    },
  };

  return (
    <>
      <div id="chart">
        <ReactApexChart
          options={state.options}
          series={state.series}
          type="line"
          height={350}
        />
      </div>
    </>
  );
};

export default Chart;
