import { Badge, Heading, Pane, Text, TextInput } from "evergreen-ui";
import React, { useEffect, useState } from "react";

const GordanGrowthCalculator = ({ data }) => {
  const GROWTH_RATE = data.lineOfFit.growthRate;
  const ANNUAL_DIVIDEND = data.projectedCurrYearDividend.projectedAmount;

  const [desiredReturn, setDesiredReturn] = useState(12.5);
  const [stockValue, setStockValue] = useState(
    (ANNUAL_DIVIDEND / (desiredReturn / 100 - GROWTH_RATE)).toFixed(2)
  );
  const [stockValueColor, setStockValueColor] = useState("neutral");

  useEffect(() => {
    setStockValue(
      (ANNUAL_DIVIDEND / (desiredReturn / 100 - GROWTH_RATE)).toFixed(2)
    );
  }, [desiredReturn, ANNUAL_DIVIDEND, GROWTH_RATE]);

  useEffect(() => {
    // if stock value is within 10% of current price, set color to yellow, if it is above 10% set color to green, if it is below 10% set color to red
    if (stockValue > data.price * 1.1) {
      setStockValueColor("green");
    } else if (stockValue < data.price * 0.9) {
      setStockValueColor("red");
    } else {
      setStockValueColor("yellow");
    }
  }, [stockValue, data.price]);
  return (
    <Pane>
      <Heading marginBottom={4} size={600}>
        Gordan Growth Calculator
      </Heading>
      <Pane
        display="flex"
        flexDirection="column"
        border="default"
        background="rgba(96,0,211,0.05)"
        padding={8}
        marginBottom={8}
      >
        <Pane
          display="flex"
          flex={1}
          marginBottom={8}
          alignItems="center"
          justifyContent="space-between"
        >
          <Text>Desired Return</Text>
          <Pane>
            <TextInput
              width={80}
              height={24}
              type="number"
              required
              isInvalid={desiredReturn <= 0}
              onChange={(e) => setDesiredReturn(parseFloat(e.target.value))}
              value={desiredReturn}
            />
            <Text marginLeft={4}>%</Text>
          </Pane>
        </Pane>
        <Pane
          display="flex"
          flex={1}
          alignItems="center"
          marginBottom={8}
          justifyContent="space-between"
        >
          <Text>Estimated Forward Annual Dividend</Text>
          <Badge color="purple">${ANNUAL_DIVIDEND.toFixed(2)}</Badge>
        </Pane>
        <Pane
          display="flex"
          flex={1}
          alignItems="center"
          marginBottom={8}
          justifyContent="space-between"
        >
          <Text>Estimated Dividend Growth Rate</Text>
          <Badge color="purple">{(GROWTH_RATE * 100).toFixed(2)}%</Badge>
        </Pane>
        <Pane
          display="flex"
          alignItems="center"
          flex={1}
          justifyContent="space-between"
        >
          <Text>Stock Valuation</Text>
          <Badge color={stockValueColor}>${stockValue}</Badge>
        </Pane>
      </Pane>
    </Pane>
  );
};

export default GordanGrowthCalculator;
