import { Pane, Spinner } from "evergreen-ui";
import React, { useEffect, useState } from "react";
import { BACKEND_URL } from "../config/config";
import Chart from "./Ticker/Chart";
import DivScore from "./Ticker/DivScore";
import StockPrice from "./Ticker/StockPrice";
import FitOfModel from "./Ticker/FitOfModel";
import GordanGrowthCalculator from "./Ticker/GordanGrowthCalculator";
import OtherInfo from "./Ticker/OtherInfo";

const Ticker = (props) => {
  const { tickerSymbol } = props.match.params;
  const TICKER = tickerSymbol.toUpperCase();
  const [data, setData] = useState();

  useEffect(() => {
    const url = new URL(`${BACKEND_URL}stock`);
    url.searchParams.append("ticker", TICKER);
    fetch(url)
      .then((res) => res.json())
      .then((data) => setData(data.stocks[TICKER]));
  }, [TICKER]);

  if (!data) {
    return (
      <Pane marginTop="20%" display="flex" justifyContent="center">
        <Spinner />
      </Pane>
    );
  }

  return (
    <Pane margin={30}>
      <Pane display="flex">
        <Pane width={"50%"} paddingRight={10}>
          <Pane display="flex" justifyContent="space-between">
            <StockPrice data={data} ticker={TICKER} />
            <DivScore data={data} />
          </Pane>
          <OtherInfo data={data} />
        </Pane>
        <Pane width={"50%"} paddingLeft={10}>
          <Pane>
            <GordanGrowthCalculator data={data} />
          </Pane>
          <Pane>
            <FitOfModel data={data} />
          </Pane>
        </Pane>
      </Pane>

      <Chart data={data} />
    </Pane>
  );
};

export default Ticker;
